//Element
function SEO(URL){
    //Element
    let Encode  = URL.toString().toLowerCase()
    //Element
    Encode      = Encode.split( "ç" ).join( "c" )
    //Element
    Encode      = Encode.split( "ğ" ).join( "g" )
    //Element
    Encode      = Encode.split( "ş" ).join( "s" )
    //Element
    Encode      = Encode.split( "ü" ).join( "u" )
    //Element
    Encode      = Encode.split( "ı" ).join( "i" )
    //Element
    Encode      = Encode.split( "ö" ).join( "o" )
    //Element
    Encode      = Encode.split( /\&+/ ).join( "-" )
    //Element
    Encode      = Encode.split( /[^a-z0-9]/ ).join( "-" )
    //Element
    Encode      = Encode.split( /-+/ ).join( "-" )
    //Element
    Encode      = Encode.trim( "-" )
    //Element
    return Encode
}
//Element
export default SEO
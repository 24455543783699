//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import propTypes from "prop-types";

//Element
class Product extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Image, Target } = this.props
        //Element
        let R062Q6 = "https://api.renga.com.tr/images/"
        //Element
        let Z347RP = R062Q6 + Image
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 plb343", href: Target },
            //Element
            React.createElement( "figure", { className: "d-flex w-100 h-auto justify-content-center align-items-center p-4 m-0 v57hk2" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Z347RP, width: "100", height: "auto" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-4 pt-0 pb-4 m-0 mt-2 d5j549" },
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center n808g5" },
                    //Element
                    React.createElement( "strong", {},
                        //Element
                        Title
                    )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 text-center text-truncate n808g5" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Product.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Image   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Product;
//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            Q8Z9J8  : []
        }
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Katıldığımız Fuarlar | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/fuar"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Katıldığımız Fuarlar | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/fuar"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Katıldığımız Fuarlar | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    VQ89V0 = () => {
        //Element
        const N124R3 = async() => {
            //Element
            const E08T40 = await Axios.get( "https://api.renga.com.tr/fair", {} )
            //Element
            .then( N522TB => {
                //Element
                this.setState({
                    //Element
                    Q8Z9J8 : N522TB.data
                })
            })
        }
        //Element
        N124R3()
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Katıldığımız Fuarlar | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/fuar" )
        //Element
        this.VQ89V0()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const G8J15X = "https://api.renga.com.tr/images/"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Breadcrumb, { ID: "0", Title: "Katıldığımız Fuarlar", Target: "/fuar" } )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mm691k" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "Katıldığımız Fuarlar"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 g1975z" },
                //Element
                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 mt-2 text-uppercase p3ir74" },
                    //Element
                    "Katıldığımız Fuarlar"
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 vm5919" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md: "11 offset-md-1" },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                this.state.Q8Z9J8.map( (S97I44) => {
                                    //Element
                                    return React.createElement( Col, { key: S97I44.ID, md:6 },
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-4 yy8948" },
                                            //Element
                                            React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d8u1e4" },
                                                //Element
                                                React.createElement( "img", { alt: S97I44.Title, title: S97I44.Title, src: G8J15X + S97I44.Image, width: "231", height: "194" } )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-auto p-0 m-0 z7xt56" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-3 h73z6d" },
                                                    //Element
                                                    S97I44.Title
                                                )
                                            )
                                        )
                                    )
                                })
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import { ReactComponent as R4A82C } from "../../../Media/Icon/019.svg";
//Install
import { ReactComponent as D5R1J7 } from "../../../Media/Icon/020.svg";
//Install
import { ReactComponent as W1E121 } from "../../../Media/Icon/021.svg";
//Install
import { ReactComponent as X7O456 } from "../../../Media/Icon/022.svg";
//Install
import { ReactComponent as UZ48B3 } from "../../../Media/Icon/023.svg";

//Element
class Social extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            K722FV : []
        }
    }
    //Element
    A0140R = () => {
        //Element
        const X47021 = async() => {
            //Element
            const DN0W33 = await Axios.get( "https://api.renga.com.tr/social", {} )
            //Element
            .then( E563V3 => {
                //Element
                this.setState({
                    //Element
                    K722FV : E563V3.data
                })
            })
        }
        //Element
        X47021()
    }
    //Element
    componentDidMount(){
        //Element
        this.A0140R()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 x2i0g4" },
            //Element
            this.state.K722FV.map( (ZCO834) => {
                //Element
                return React.createElement( "ul", { key: ZCO834.ID, className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 r7jl46" },
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3", href: ZCO834.Facebook },
                            //Element
                            React.createElement( R4A82C, {} )     
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 ms-4", href: ZCO834.Twitter },
                            //Element
                            React.createElement( D5R1J7, {} )                    
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 ms-4", href: ZCO834.Linkedin },
                            //Element
                            React.createElement( W1E121, {} )                    
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 ms-4", href: ZCO834.Instagram },
                            //Element
                            React.createElement( X7O456, {} )                   
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 ms-4", href: ZCO834.Youtube },
                            //Element
                            React.createElement( UZ48B3, {} )                    
                        )
                    )
                )
            })
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Social;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as PZ767F } from "../../Media/Icon/003.svg";

//Element
class Search extends Component {
    //Element
    C4710E = () => {
        //Element
        let U344GC = document.getElementsByClassName( "p88d5z" )[0]
        //Element
        U344GC.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "float-start w-auto p-0 m-0 me-4 bg-transparent border-0 c40he8", type: "button", onClick: this.C4710E },
            //Element
            React.createElement( PZ767F, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Search;
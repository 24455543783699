//Install
import React, { Component } from "react";
//Install
import { ReactComponent as S48C12 } from "../../Media/Icon/002.svg";

//Element
class User extends Component {
    //Element
    B0652I = () => {
        //Element
        //let T5115V = document.getElementsByClassName( "v95986" )[0]
        //Element
        //T5115V.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "float-start w-auto p-0 m-0 me-4 bg-transparent border-0 vwm262", type: "button", onClick: this.B0652I },
            //Element
            React.createElement( S48C12, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default User;
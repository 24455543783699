//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Thumb extends Component {
    //Element
    H020NM = (e) => {
        //Element
        e.preventDefault()
        //Element
        let A8V56U = e.target.currentSrc
        //Element
        let N1Z726 = document.querySelector( ".io4945 img" )
        //Element
        N1Z726.src = e.target.currentSrc
    }
    //Element
    render() {
        //Element
        const { ID, Title, Image, Target } = this.props
        //Element
        let D670RW = "https://api.renga.com.tr/images/"
        //Element
        let G824ZA = D670RW + Image
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-3 m-0 mb-3 o770kh", href: Target, onClick: this.H020NM },
            //Element
            React.createElement( "img", { alt: Title, title: Title, src: G824ZA, width: "196", height: "270" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Thumb.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Image  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Thumb;
//Install
import React, { useState, useEffect } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function Default(){
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const T967NQ = async() => {
            //Element
            const Z871Y6 = await Axios.get( "https://api.renga.com.tr/timeline", {} )
            //Element
            .then( SQ64J5 => {
                //Element
                C68006( SQ64J5.data )
            })
        }
        //Element
        T967NQ()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s8ib47" },
        //Element
        React.createElement( Row, {},
            //Element
            G7TI26.map( (R56O11) => {
                //Element
                if( R56O11.ID == "18" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 } ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 position-relative x31c49" } ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 position-relative vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 position-relative s5961n" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 position-relative n972ac" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            )
                        )
                    )
                //Element
                } else if( R56O11.ID == "19" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-end w-100 p-0 m-0 position-relative text-end vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-end w-100 position-relative j076rn" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-end w-auto text-end position-relative d79z07" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x31c49" } ),
                                )
                            )
                        )
                    )
                //Element
                } else if( R56O11.ID == "20" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 } ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x31c49" } ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 position-relative s5961n" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 position-relative n972ac" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            )
                        )
                    )
                //Element
                } else if( R56O11.ID == "21" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-end w-100 p-0 m-0 position-relative text-end vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-end w-100 position-relative j076rn" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-end w-auto text-end position-relative d79z07" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x31c49" } ),
                                )
                            )
                        )
                    )
                //Element
                } else if( R56O11.ID == "22" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 } ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x31c49" } ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 position-relative s5961n" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 position-relative n972ac" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            )
                        )
                    )
                //Element
                } else if( R56O11.ID == "23" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-end w-100 p-0 m-0 position-relative text-end vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-end w-100 position-relative j076rn" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-end w-auto text-end position-relative d79z07" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x31c49" } ),
                                )
                            )
                        )
                    )
                //Element
                } else if( R56O11.ID == "24" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 } ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x31c49" } ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 position-relative s5961n" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 position-relative n972ac" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            )
                        )
                    )
                //Element
                } else if( R56O11.ID == "25" ){
                    //Element
                    return React.createElement( Col, { md:12, key: R56O11.ID },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-end w-100 p-0 m-0 position-relative text-end vcu059" },
                                        //Element
                                        React.createElement( "h3", { className: "float-end w-100 position-relative j076rn" },
                                            //Element
                                            R56O11.Title
                                        ),
                                        //Element
                                        React.createElement( "p", { className: "float-end w-auto text-end position-relative d79z07" },
                                            //Element
                                            R56O11.Summary
                                        )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 p5u1c6" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x31c49" } ),
                                )
                            )
                        )
                    )
                }
            })
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default
//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Filter extends Component {
    //Element
    H423S2 = () => {
        //Element
        const { Target }     = this.props
        //Element
        window.location.href = Target
    }
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "button", { id: ID, name: ID, className: "float-start w-100 p-0 m-0 position-relative a610tx", type: "button", onClick: this.H423S2 },
            //Element
            React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 q9754e" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Filter.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Image  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Filter;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as D0FN72 } from "../../Media/Icon/009.svg";
//Install
import { ReactComponent as P39T4P } from "../../Media/Icon/010.svg";
//Install
import { ReactComponent as X253MO } from "../../Media/Icon/011.svg";
//Install
import propTypes from "prop-types";

//Element
class Round extends Component {
    //Element
    W007G1 = () => {
        //Element
        const { Target } = this.props
        //Element
        window.location.href = Target
    }
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        let A53R5Q
        //Element
        if( ID == "1" ){
            //Element
            A53R5Q = React.createElement( D0FN72, {} )
        //Element
        } else if( ID == "2" ){
            //Element
            A53R5Q = React.createElement( P39T4P, {} )
        //Element
        } else if( ID == "3" ){
            //Element
            A53R5Q = React.createElement( X253MO, {} )
        } else {
            //Element
            A53R5Q = React.createElement( D0FN72, {} )
        }
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 rounded-circle h709p2", type: "button", onClick: this.W007G1 },
            //Element
            React.createElement( "div", { className: "float-start w-auto p-0 m-0 v6q52t" },
                //Element
                A53R5Q
            ),
            //Element
            React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-3 tz773s" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Round.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Round;
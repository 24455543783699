//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Menu from "./Footer/Menu";
//Install
import Brand from "./Footer/Brand";
//Install
import Video from "./Shortcode/Video/Default";
//Install
import Submenu from "./Footer/Submenu";
//Install
import Copyright from "./Footer/Copyright";
//Install
import Information from "./Footer/Information";

//Element
class Footer extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            C47HB6 : [],
            //Element
            Q0P9X6 : []
        }
    }
    //Element
    EY884S = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/social", {} )
        //Element
        .then( J40W5C => {
            //Element
            this.setState({
                //Element
                C47HB6 : J40W5C.data
            })
        })
    }
    //Element
    PD819D = () => {
        //Element
        const I7X66E = async() => {
            //Element
            const W1IR73 = await Axios.get( "https://api.renga.com.tr/video", {} )
            //Element
            .then( QT248Y => {
                //Element
                this.setState({
                    //Element
                    Q0P9X6 : QT248Y.data
                })
            })
        }
        //Element
        I7X66E()
    }
    //Element
    componentDidMount(){
        //Element
        this.EY884S()
        //Element
        this.PD819D()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "footer", { className: "float-start w-100 p-0 m-0 ibv050" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 t52n6q" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            this.state.C47HB6.map( (Data) => {
                                //Element
                                return React.createElement( Brand, { key: Data.ID, ID: Data.ID.toString(), Title: "Renga", Facebook: Data.Facebook, X: Data.Twitter, Instagram: Data.Instagram, Youtube: Data.Youtube, Linkedin: Data.Linkedin, Target: "/" } )
                            })
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Information, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s8cw73" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Copyright, { ID: "0", Title: "© Telif Hakkı 2024 Renga.com.tr Tüm Hakları Saklıdır.", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Submenu, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-center align-items-center position-fixed start-0 end-0 top-0 bottom-0 d-none a045v4" },
                //Element
                this.state.Q0P9X6.map( (L526EU) => {
                    //Element
                    return React.createElement( Video, { key: L526EU.ID, ID: L526EU.ID.toString(), Title: L526EU.Title, iFrame: L526EU.iFrame, Target: "/" } )
                })
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;
//Install
import React, { Component } from "react";
//Install
import Parser from "html-react-parser";
//Install
import { ReactComponent as DK84F0 } from "../../../Media/Icon/028.svg";
//Install
import { ReactComponent as Z705IE } from "../../../Media/Icon/029.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    G0V0B9 = (e) => {
        //Element
        e.preventDefault()
        //Element
        const { ID } = this.props
        //Element
        let U5H770 = "Web_"
        //Element
        let U8430R = U5H770 + ID
        //Element
        document.getElementsByClassName( "a045v4" )[0].classList.add( "d-none" )
        //Element
        document.getElementById( U8430R ).classList.add( "d-none" )
        //Element
        window.location.reload()
    }
    //Element
    render() {
        //Element
        const { ID, Title, iFrame, Target } = this.props
        //Element
        let VT58S3, IL98E6
        //Element
        VT58S3 = "Web_"
        //Element
        IL98E6 = VT58S3 + ID
        //Element
        const Content = React.createElement( "div", { id: IL98E6, className: "d-flex w-100 flex-column justify-content-center align-items-center p-4 m-0 bg-white d-none f086jk" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center y5809d" },
                //Element
                React.createElement( "div", { className: "d-flex justify-content-center align-items-center rounded-circle uvn006" },
                    //Element
                    React.createElement( DK84F0, {} )
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 text-start g3i8h0" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "button", { className: "float-end w-auto p-0 m-0 bg-transparent border-0 z626j7", onClick: this.G0V0B9 },
                    //Element
                    React.createElement( Z705IE, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 h-auto p-4 ps-0 pe-0 pt-3 m-0 bg-white overflow-hidden t27y4f" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative fc388j" },
                    //Element
                    Parser(iFrame)
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    iFrame   : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired,
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as BK4P01 } from "../../../Media/Icon/012.svg";
//Install
import { ReactComponent as UR880L } from "../../../Media/Icon/013.svg";

//Element
class Navigation extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute y48f7l" },
            //Element
            React.createElement( "button", { id: "Web_Z39J28", name: "Web_Z39J28", className: "float-start w-auto bg-transparent border-0 j2p09b", type: "button" },
                //Element
                React.createElement( BK4P01, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_HJ0679", name: "Web_HJ0679", className: "float-end w-auto bg-transparent border-0 s525qg", type: "button" },
                //Element
                React.createElement( UR880L, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Navigation;
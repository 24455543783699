//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import SEO from "../../Function/SEO";
//Install
import Nav from "../../Shortcode/Blog/Navigation";
//Install
import Small from "../../Shortcode/Blog/Small";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Blog";

//Element
function Y26F5M(){
    //Element
    const { ID } = useParams()
    //Element
    let Z1K17M = " | Blog - Renga.com.tr"
    //Element
    useEffect( () => {
        //Element
        const G566D6 = async() => {
            //Element
            const V32Z4C = await Axios.get( "https://api.renga.com.tr/blog/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( C16J5B => {
                //Element
                let OM175Z     = document.querySelector( "meta[property='og:title']" )
                //Element
                let T00A3U     = document.querySelector( "meta[property='og:description']" )
                //Element
                let HU221M     = document.querySelector( "meta[property='og:image']" )
                //Element
                let V337GE     = document.querySelector( "meta[property='og:url']" )
                //Element
                OM175Z.content = C16J5B.data[0].Title + Z1K17M
                //Element
                T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                HU221M.content = "https://renga.com.tr/favicon.svg"
                //Element
                V337GE.content = window.location.href
                //Element
                let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
                //Element
                let D68001     = document.querySelector( "meta[name='twitter:description']" )
                //Element
                let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
                //Element
                let S577FO     = document.querySelector( "meta[name='twitter:url']" )
                //Element
                N09HE5.content = C16J5B.data[0].Title + Z1K17M
                //Element
                D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                N94MJ9.content = "https://renga.com.tr/favicon.svg"
                //Element
                S577FO.content = window.location.href
                //Element
                let AHF319     = document.querySelector( "meta[itemprop='name'" )
                //Element
                let W2LB44     = document.querySelector( "meta[itemprop='description']" )
                //Element
                let K08577     = document.querySelector( "meta[itemprop='image']" )
                //Element
                AHF319.content = C16J5B.data[0].Title + Z1K17M
                //Element
                W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                K08577.content = "https://renga.com.tr/favicon.svg"
                //Element
                document.title = C16J5B.data[0].Title + Z1K17M
                //Element
                document.getElementsByTagName( "meta" )["description"].content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", window.location.href )
            })
        }
        //Element
        G566D6()
    })
}
//Element
function Article(){
    //Element
    Y26F5M()
    //Element
    const { ID } = useParams()
    //Element
    let W837LE = "https://api.renga.com.tr/images/"
    //Element
    let BT8B34 = "/blog-detay/"
    //Element
    let L02L9X = "/"
    //Element
    useEffect( () => {
        //Element
        let Z41T6Y = document.getElementsByClassName( "g283uf" )[0]
        //Element
        Z41T6Y.classList.add( "q31v3i" )
    })
    //Element
    const F752DC = {
        //Element
        spaceBetween    : "12",
        //Element
        slidesPerView   : "3",
        //Element
        navigation      : {
            //Element
            nextEl      : ".s525qg",
            //Element
            prevEl      : ".j2p09b"
        },
        //Element
        loop            : true,
        //Element
        freeMode        : true,
        //Element
        breakpoints     : {
            //Element
            320:{
                //Element
                slidesPerView : 1
            },
            //Element
            360:{
                //Element
                slidesPerView : 1
            },
            //Element
            480:{
                //Element
                slidesPerView : 1
            },
            //Element
            504:{
                //Element
                slidesPerView : 1
            },
            //Element
            600:{
                //Element
                slidesPerView : 1
            },
            //Element
            720:{
                //Element
                slidesPerView : 2
            },
            //Element
            768:{
                //Element
                slidesPerView : 2
            },
            //Element
            1024:{
                //Element
                slidesPerView : 3
            },
            //Element
            1280:{
                //Element
                slidesPerView : 3
            }
        },
        //Element
        modules         : [ Navigation, Pagination, Autoplay ]
    }
    //Element
    const [ SQ419L, E3ZZ97 ] = React.useState([])
    //Element
    const [ E38142, I3N71A ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const G566D6 = async() => {
            //Element
            const V32Z4C = await Axios.get( "https://api.renga.com.tr/blog/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            const R5H228 = await Axios.get( "https://api.renga.com.tr/blog/not", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            E3ZZ97( V32Z4C.data )
            //Element
            I3N71A( R5H228.data )
        }
        //Element
        G566D6()
    }, [])
    //Element
    const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative x54j5k" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            SQ419L.map( W9422I => {
                                //Element
                                return React.createElement( Breadcrumb, { key: W9422I.ID, ID: W9422I.ID.toString(), Title: W9422I.Title, Target: window.location.href } )
                            })
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 c10406" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        SQ419L.map( (A01559) => {
                            //Element
                            return React.createElement( "picture", { key: A01559.ID, className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 s40u1s" },
                                //Element
                                React.createElement( "img", { alt: A01559.Title, title: A01559.Title, src: W837LE + A01559.Image, width: "1296", height: "730" } )
                            )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        SQ419L.map( (A01559) => {
                            //Element
                            return React.createElement( "div", { key: A01559.ID, className: "float-start w-100 p-0 m-0 mt-5 q1e14m" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center cx8v38" },
                                    //Element
                                    A01559.Title
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 a4r730" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-1 ng230w" },
                                        //Element
                                        A01559.Date
                                    ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-1 p229n4" },
                                        //Element
                                        A01559.Category
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 p20c74" },
                                    //Element
                                    Parser( A01559.Content )
                                )
                            )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 pb-5 m-0 mt-4 h685jr" } ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative e2448n" },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...F752DC },
                                //Element
                                E38142.map( (H432SZ) => {
                                    //Element
                                    return React.createElement( SwiperSlide, { key: H432SZ.ID },
                                        //Element
                                        React.createElement( Small, { ID: H432SZ.ID.toString(), Title: H432SZ.Title, Date: H432SZ.Date, Category: H432SZ.Category, Image: H432SZ.Image, Target: BT8B34 + SEO(H432SZ.Title) + L02L9X + H432SZ.ID } )
                                    )
                                })
                            ),
                            //Element
                            React.createElement( Nav, {} )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Article;
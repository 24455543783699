//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Application extends Component {
    //Element
    constructor(){
        //Element
        super()
        //Element
        this.state = {
            //Element
            O401CE : [],
            //Element
            H3R1U1 : "",
            //Element
            QO8810 : ""
        }
        //Element
        this.G979MP = this.G979MP.bind( this );
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Hemen Başvur | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/hemen-basvur"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Hemen Başvur | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/hemen-basvur"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Hemen Başvur | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    G979MP(e) {
        //Element
        this.setState({
            //Element
            H3R1U1 : e.target.value
        })
    }
    //Element
    L2W298 = () => {
        //Element
        const C38374 = async() => {
            //Element
            const AX642X = await Axios.get( "https://api.renga.com.tr/status/list" )
            //Element
            .then( WI205E => {
                //Element
                this.setState({
                    //Element
                    O401CE : WI205E.data
                })
            })
        }
        //Element
        C38374()
    }
    //Element
    A7V13G = (e) => {
        //Element
        const V674WP    = document.getElementById( "Web_T3V97M" )
        //Element
        const X217RI    = document.getElementById( "Web_L2253G" )
        //Element
        const L842E8    = new FormData()
        //Element
        V674WP.disabled = true
        //Element
        if( e.target.files[0] != null ){
            //Element
            X217RI.innerHTML = e.target.files[0].name
            //Element
            L842E8.append( "image", e.target.files[0] )
            //Element
            const W08655 = async() => {
                //Element
                const YWQ673 = await Axios.post( "https://api.renga.com.tr/upload", L842E8 )
                //Element
                .then( P2892H => {
                    //Element
                    this.setState({
                        //Element
                        QO8810 : P2892H.data
                    })
                    //Element
                    if( P2892H.statusText == "OK" ){
                        //Element
                        V674WP.disabled = false
                    }
                })
            }
            //Element
            W08655()
        //Element
        } else {
            //Element
            X217RI.focus()
        }
    }
    //Element
    L90Y03 = (e) => {
        //Element
        e.preventDefault()
        //Element
        const AZ328O = this.state.H3R1U1
        //Element
        const SV31Y0 = this.state.QO8810
        //Element
        let AA484K   = new Date()
        //Element
        let W128VY   = AA484K.toLocaleDateString( "tr-TR" )
        //Element
        let I90WK5, Z5X51V, WK918P, N4Q97A, KO23O2, I5YT64, G457JS, S7HD61, K9T212, ESB752, F661XF, U733OW, ZV2568, WD421E, T0DL06, U90AS6
        //Element
        I90WK5       = document.getElementById( "Web_T36Q72" )
        //Element
        Z5X51V       = document.getElementById( "Web_Y5R23A" ).value
        //Element
        WK918P       = document.getElementById( "Web_XQ4850" ).value
        //Element
        N4Q97A       = document.getElementById( "Web_I9E538" ).value
        //Element
        KO23O2       = document.getElementById( "Web_KJ676B" ).value
        //Element
        I5YT64       = document.getElementById( "Web_LY570L" ).value
        //Element
        G457JS       = document.getElementById( "Web_QK921W" ).value
        //Element
        S7HD61       = document.getElementById( "Web_BA7256" ).value
        //Element
        K9T212       = document.getElementById( "Web_H2775N" ).value
        //Element
        ESB752       = document.getElementById( "Web_J1490L" ).value
        //Element
        F661XF       = document.getElementById( "Web_A36P4W" ).value
        //Element
        U733OW       = document.getElementById( "Web_A473MH" ).value
        //Element
        ZV2568       = document.getElementById( "Web_C3M92Z" ).value
        //Element
        WD421E       = document.getElementById( "Web_GV376I" ).value
        //Element
        T0DL06       = document.getElementById( "Web_W141EA" ).value
        //Element
        U90AS6       = document.getElementsByClassName( "kp51h3" )[0]
        //Element
        Axios.get( "https://api.renga.com.tr/application/add", {
            //Element
            params : {
                //Element
                Name       : Z5X51V,
                //Element
                Surname    : WK918P,
                //Element
                Date       : W128VY,
                //Element
                Email      : N4Q97A,
                //Element
                Telephone  : KO23O2,
                //Element
                Address    : I5YT64,
                //Element
                City       : G457JS,
                //Element
                Day        : S7HD61,
                //Element
                Month      : K9T212,
                //Element
                Year       : ESB752,
                //Element
                Gender     : AZ328O,
                //Element
                School     : F661XF,
                //Element
                Section    : U733OW,
                //Element
                Graduation : ZV2568,
                //Element
                Position   : WD421E,
                //Element
                CV         : SV31Y0,
                //Element
                Letter     : T0DL06
            }
        })
        //Element
        document.getElementById( "Web_T36Q72" ).reset()
        //Element
        U90AS6.classList.remove( "d-none" )
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Hemen Başvur | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/hemen-basvur" )
        //Element
        this.L2W298()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Breadcrumb, { ID: "0", Title: "Hemen Başvur", Target: "/hemen-basvur" } )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u09dl7" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "Hemen Başvur"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t46604" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 y422cf" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 mt-5 text-center lh-1 m8591s" },
                                    //Element
                                    "İş Başvuru Formu"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "form", { id: "Web_T36Q72", name: "Web_T36Q72", className: "float-start w-100 p-0 m-0 y422cf", onSubmit: this.L90Y03 },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 pb-2 m-0 mt-3 text-start n90r54" },
                                    //Element
                                    "Kişisel Bilgiler"
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 yf340s" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md: "9 offset-md-3" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Adınız"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_Y5R23A", name: "Web_Y5R23A", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Soyadınız"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_XQ4850", name: "Web_XQ4850", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "E-Posta"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_I9E538", name: "Web_I9E538", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "email", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Cep Telefon Numarası"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_KJ676B", name: "Web_KJ676B", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "tel", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:8 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Adres"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_LY570L", name: "Web_LY570L", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "text", maxLength: "999", required: "required" } ),
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Doğum Yeri"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_QK921W", name: "Web_QK921W", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:8 },
                                                    //Element
                                                    React.createElement( Row, {},
                                                        //Element
                                                        React.createElement( Col, { md:12 },
                                                            //Element
                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                                //Element
                                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                                    //Element
                                                                    "Doğum Tarihi"
                                                                )
                                                            )
                                                        ),
                                                        //Element
                                                        React.createElement( Col, { md:4 },
                                                            //Element
                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 gx436f" },
                                                                //Element
                                                                React.createElement( "input", { id: "Web_BA7256", name: "Web_BA7256", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", placeholder: "Gün", type: "text", required: "required" } )
                                                            )
                                                        ),
                                                        //Element
                                                        React.createElement( Col, { md:4 },
                                                            //Element
                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 gx436f" },
                                                                //Element
                                                                React.createElement( "input", { id: "Web_H2775N", name: "Web_H2775N", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", placeholder: "Ay", type: "text", required: "required" } )
                                                            )
                                                        ),
                                                        //Element
                                                        React.createElement( Col, { md:4 },
                                                            //Element
                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 gx436f" },
                                                                //Element
                                                                React.createElement( "input", { id: "Web_J1490L", name: "Web_J1490L", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", placeholder: "Yıl", type: "text", required: "required" } )
                                                            )
                                                        )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Cinsiyet"
                                                        ),
                                                        //Element
                                                        React.createElement( "fieldset", { id: "Web_E06H3O", name: "Web_E06H3O", className: "float-start w-100 p-0 m-0" },
                                                            //Element
                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 v264h0" },
                                                                //Element
                                                                React.createElement( "input", { id: "Web_N6W5K5", name: "Web_N6W5K5", className: "float-start w-auto p-0 m-0 c7p65m", value: "Bay", type: "radio", checked: this.state.H3R1U1 === "Bay", onChange: this.G979MP } ),
                                                                //Element
                                                                React.createElement( "label", { className: "float-start w-auto p-0 m-0 ms-2 lh-1 kr332c", htmlFor: "Web_N6W5K5" },
                                                                    //Element
                                                                    "Bay"
                                                                )
                                                            ),
                                                            //Element
                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 v264h0" },
                                                                //Element
                                                                React.createElement( "input", { id: "Web_R2J16W", name: "Web_R2J16W", className: "float-start w-auto p-0 m-0", value: "Bayan", type: "radio", checked: this.state.H3R1U1 === "Bayan", onChange: this.G979MP } ),
                                                                //Element
                                                                React.createElement( "label", { className: "float-start w-auto p-0 m-0 ms-2 lh-1 kr332c", htmlFor: "Web_R2J16W" },
                                                                    //Element
                                                                    "Bayan"
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 pb-2 m-0 mt-3 text-start n90r54" },
                                    //Element
                                    "Eğitim Bilgileri"
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 yf340s" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md: "9 offset-md-3" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "En Son Mezun Olduğunuz Okul"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_A36P4W", name: "Web_A36P4W", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Bölüm"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_A473MH", name: "Web_A473MH", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Yıl"
                                                        ),
                                                        //Element
                                                        React.createElement( "input", { id: "Web_C3M92Z", name: "Web_C3M92Z", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 o306y5", type: "text", required: "required" } )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 pb-2 m-0 mt-3 text-start n90r54" },
                                    //Element
                                    "Başvuru Bilgileri"
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 yf340s" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md: "9 offset-md-3" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Başvurduğunuz Pozisyon"
                                                        ),
                                                        //Element
                                                        React.createElement( "select", { id: "Web_GV376I", name: "Web_GV376I", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                                            //Element
                                                            this.state.O401CE.map( (X6E1U4) => {
                                                                //Element
                                                                return React.createElement( "option", { key: X6E1U4.ID, value: X6E1U4.Title },
                                                                    //Element
                                                                    X6E1U4.Title
                                                                )
                                                            })
                                                        )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-2 b05r1e" },
                                                            //Element
                                                            "CV"
                                                        ),
                                                        //Element
                                                        React.createElement( "label", { id: "Web_BG3179", className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-2 position-relative z4n31j", htmlFor: "Web_A232V2" },
                                                            //Element
                                                            React.createElement( "div", { id: "Web_L2253G", name: "Web_L2253G", className: "float-start w-100 p-0 m-0 mt-1 text-center m6446w", htmlFor: "Web_A232V2" },
                                                                //Element
                                                                "Dosya Seçin"
                                                            ),
                                                            //Element
                                                            React.createElement( "input", { id: "Web_A232V2", name: "Web_A232V2", className: "float-start w-100 p-0 m-0 o27s67 d-none", type: "file", accept: ".pdf", onChange: this.A7V13G } )
                                                        )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:12 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mb-1 b05r1e" },
                                                            //Element
                                                            "Ön Yazı"
                                                        ),
                                                        //Element
                                                        React.createElement( "textarea", { id: "Web_W141EA", name: "Web_W141EA", className: "float-start w-100 p-3 m-0 mt-2 t17t0b", rows: "5", cols: "20", maxLength: "999" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "button", { id: "Web_T3V97M", name: "Web_T3V97M", className: "float-start w-100 p-0 m-0 g301x4", type: "submit" },
                                                            //Element
                                                            "Hemen Gönder"
                                                        )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:8 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 gx436f" },
                                                        //Element
                                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-end d-none kp51h3" },
                                                            //Element
                                                            "Başvuru Gönderildi."
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )   
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Application;
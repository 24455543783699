//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Axios from "axios";
//Install
import Nav from "../../Shortcode/Media/Navigation";
//Install
import Image from "../../Shortcode/Media/Default";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            E381L3 : []
        }
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Medya | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/medya"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Medya | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/medya"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Medya | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    A44694 = () => {
        //Element
        const Z111Q9 = async() => {
            //Element
            const XM9R15 = await Axios.get( "https://api.renga.com.tr/factory", {} )
            //Element
            .then( E400UG => {
                //Element
                this.setState({
                    //Element
                    E381L3 : E400UG.data
                })
            })
        }
        //Element
        Z111Q9()
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Medya | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/medya" )
        //Element
        this.A44694()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween         : "0",
            //Element
            slidesPerView        : "3",
            //Element
            navigation           : {
                //Element
                nextEl           : "#Web_E4090L",
                //Element
                prevEl           : "#Web_W474VO"
            },
            //Element
            centeredSlides       : true,
            //Element
            roundLengths         : true,
            //Element
            loop                 : true,
            //Element
            initialSlide         : 3,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView  : 1,
                    //Element
                    initialSlide   : 1,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                360:{
                    //Element
                    slidesPerView  : 1,
                    //Element
                    initialSlide   : 1,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                480:{
                    //Element
                    slidesPerView  : 1,
                    //Element
                    initialSlide   : 1,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                504:{
                    //Element
                    slidesPerView  : 1,
                    //Element
                    initialSlide   : 1,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                600:{
                    //Element
                    slidesPerView  : 1,
                    //Element
                    initialSlide   : 1,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                720:{
                    //Element
                    slidesPerView  : 2,
                    //Element
                    initialSlide   : 2,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                768:{
                    //Element
                    slidesPerView  : 2,
                    //Element
                    initialSlide   : 2,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                1024:{
                    //Element
                    slidesPerView  : 2,
                    //Element
                    initialSlide   : 2,
                    //Element
                    centeredSlides : false,
                    //Element
                    roundLengths   : false
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3,
                    //Element
                    initialSlide   : 3,
                }
            },
            //Element
            modules              : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Breadcrumb, { ID: "0", Title: "Medya", Target: "/medya" } )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 x8r65r" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "Medya"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ej826o" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 position-relative ya879e" },
                    //Element
                    React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                        //Element
                        this.state.E381L3.map( (FL482P) => {
                            //Element
                            return React.createElement( SwiperSlide, { key: FL482P.ID },
                                //Element
                                React.createElement( Image, { ID: FL482P.ID, Title: FL482P.Title, Image: FL482P.Image, Target: "/" } )
                            )
                        })
                    ),
                    //Element
                    React.createElement( Nav, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Prize extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Target } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 p-0 m-0 justify-content-start align-items-center p-0 m-0 b032o1" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 position-relative lh-1", href: "/" },
                    //Element
                    "Başlangıç"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "label", { className: "float-start w-auto p-0 position-relative" },
                    //Element
                    ">"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 position-relative lh-1", href: "/blog" },
                    //Element
                    "Blog"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "label", { className: "float-start w-auto p-0 position-relative" },
                    //Element
                    ">"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 position-relative lh-1", href: Target },
                    //Element
                    Title
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Prize.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Prize;
//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Reference extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Image, Target } = this.props
        //Element
        let VUV237 = "https://api.renga.com.tr/images/"
        //Element
        let E59O30 = VUV237 + Image
        //Element
        const Content = React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-2 mb-5 z176ny" },
            //Element
            React.createElement( "img", { alt: Title, title: Title, src: E59O30, width: "110", height: "auto" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Reference.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Image  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Reference;
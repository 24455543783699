//Install
import React, { Component } from "react";
//Install
import { ReactComponent as VG38J3 } from "../../Media/Icon/014.svg";
//Install
import propTypes from "prop-types";

//Element
class Video extends Component {
    //Element
    W17S9Y = (e) => {
        //Element
        e.preventDefault()
        //Element
        const { ID } = this.props
        //Element
        let U5H770 = "Web_"
        //Element
        let U8430R = U5H770 + ID
        //Element
        let V61B06 = document.getElementsByTagName( "body" )[0]
        //Element
        let V6BC20 = document.getElementsByClassName( "a045v4" )[0]
        //Element
        let D011E3 = document.getElementById( U8430R )
        //Element
        V6BC20.classList.remove( "d-none" )
        //Element
        D011E3.classList.remove( "d-none" )
        //Element
        V61B06.classList.add( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const { ID, Title, Duration, Image, Target } = this.props
        //Element
        let X2C074 = "https://api.renga.com.tr/images/"
        //Element
        let I2B666 = X2C074 + Image
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 position-relative q4jg86", title: Title, href: Target, onClick: this.W17S9Y },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 m859e6" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: I2B666, width: "312", height: "175" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute end-0 top-0 wo5485" },
                //Element
                React.createElement( "span", { className: "float-end w-100 p-0 m-0 mt-3 me-3 text-center q8y504" },
                    //Element
                    Duration
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 p-0 m-0 justify-content-center align-items-center position-absolute start-0 top-0 hs543w" },
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle f57d35" },
                    //Element
                    React.createElement( VG38J3, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Video.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    Duration : propTypes.string.isRequired,
    Image    : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired
}
//Element
export default Video;
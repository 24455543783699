//Install
import React, { useEffect, useState } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { useParams } from "react-router-dom";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Prize";

//Element
function Y26F5M(){
    //Element
    const { ID } = useParams()
    //Element
    let Z1K17M = " | Ödül - Renga.com.tr"
    //Element
    useEffect( () => {
        //Element
        const G566D6 = async() => {
            //Element
            const V32Z4C = await Axios.get( "https://api.renga.com.tr/prize/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( C16J5B => {
                //Element
                let OM175Z     = document.querySelector( "meta[property='og:title']" )
                //Element
                let T00A3U     = document.querySelector( "meta[property='og:description']" )
                //Element
                let HU221M     = document.querySelector( "meta[property='og:image']" )
                //Element
                let V337GE     = document.querySelector( "meta[property='og:url']" )
                //Element
                OM175Z.content = C16J5B.data[0].Title + Z1K17M
                //Element
                T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                HU221M.content = "https://renga.com.tr/favicon.svg"
                //Element
                V337GE.content = window.location.href
                //Element
                let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
                //Element
                let D68001     = document.querySelector( "meta[name='twitter:description']" )
                //Element
                let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
                //Element
                let S577FO     = document.querySelector( "meta[name='twitter:url']" )
                //Element
                N09HE5.content = C16J5B.data[0].Title + Z1K17M
                //Element
                D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                N94MJ9.content = "https://renga.com.tr/favicon.svg"
                //Element
                S577FO.content = window.location.href
                //Element
                let AHF319     = document.querySelector( "meta[itemprop='name'" )
                //Element
                let W2LB44     = document.querySelector( "meta[itemprop='description']" )
                //Element
                let K08577     = document.querySelector( "meta[itemprop='image']" )
                //Element
                AHF319.content = C16J5B.data[0].Title + Z1K17M
                //Element
                W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                K08577.content = "https://renga.com.tr/favicon.svg"
                //Element
                document.title = C16J5B.data[0].Title + Z1K17M
                //Element
                document.getElementsByTagName( "meta" )["description"].content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", window.location.href )
            })
        }
        //Element
        G566D6()
    })
}
//Element
function Default(){
    //Element
    Y26F5M()
    //Element
    const { ID } = useParams()
    //Element
    let X99I79, B587XC, P2D5R6
    //Element
    let G377LG = "https://api.renga.com.tr/images/"
    //Element
    const [ TC4461, Y908H4 ] = useState([])
    //Element
    useEffect( () => {
        //Element
        const K8744H = async() => {
            //Element
            const V52T7Y = await Axios.get( "https://api.renga.com.tr/prize/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( (O466O5) => {
                //Element
                Y908H4( O466O5.data )
            })
        }
        //Element
        K8744H()
    }, [])
    //Element
    const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            TC4461.map( RZ49G3 => {
                                //Element
                                return React.createElement( Breadcrumb, { key: RZ49G3.ID, ID: RZ49G3.ID.toString(), Title: RZ49G3.Title, Target: window.location.href } )
                            })
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d62j49" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                    //Element
                                    TC4461.map( RZ49G3 => {
                                        //Element
                                        return RZ49G3.Title
                                    })
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 y0c044" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-5 h728di" },
                            //Element
                            React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle hfn308" },
                                //Element
                                TC4461.map( RZ49G3 => {
                                    //Element
                                    return React.createElement( "img", { key: RZ49G3.ID, alt: RZ49G3.Title, title: RZ49G3.Title, src: G377LG + RZ49G3.Image, width: "120", height: "120" } )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:9 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-5 h728di" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s98067" },
                                //Element
                                TC4461.map( RZ49G3 => {
                                    //Element
                                    return Parser( RZ49G3.Summary )
                                })
                            )
                        ),
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-2 h728di" },
                                    //Element
                                    TC4461.map( RZ49G3 => {
                                        //Element
                                        if( RZ49G3.First != null && RZ49G3.First != "null" ){
                                            //Element
                                            return React.createElement( "picture", { key: RZ49G3.ID, className: "float-start w-100 p-0 m-0 i268vr" },
                                                //Element
                                                React.createElement( "img", { alt: RZ49G3.Title, title: RZ49G3.Title, src: G377LG + RZ49G3.First, width: "1000", height: "500" } )
                                            )
                                        }
                                    })
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-2 h728di" },
                                    //Element
                                    TC4461.map( RZ49G3 => {
                                        //Element
                                        if( RZ49G3.Second != null && RZ49G3.Second != "null" ){
                                            //Element
                                            return React.createElement( "picture", { key: RZ49G3.ID, className: "float-start w-100 p-0 m-0 i268vr" },
                                                //Element
                                                React.createElement( "img", { alt: RZ49G3.Title, title: RZ49G3.Title, src: G377LG + RZ49G3.Second, width: "1000", height: "500" } )
                                            )
                                        }
                                    })
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-2 h728di" },
                                    //Element
                                    TC4461.map( RZ49G3 => {
                                        //Element
                                        if( RZ49G3.Third != null && RZ49G3.Third != "null" ){
                                            //Element
                                            return React.createElement( "picture", { key: RZ49G3.ID, className: "float-start w-100 p-0 m-0 i268vr" },
                                                //Element
                                                React.createElement( "img", { alt: RZ49G3.Title, title: RZ49G3.Title, src: G377LG + RZ49G3.Third, width: "1000", height: "500" } )
                                            )
                                        }
                                    })
                                )
                            )
                        ),
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;
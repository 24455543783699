//Install
import React, { Component } from "react";
//Install
import { ReactComponent as S0L8N7 } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as I2B824 } from "../../Media/Icon/004.svg";
//Install
import { ReactComponent as Y27871 } from "../../Media/Icon/005.svg";
//Install
import { ReactComponent as RG2902 } from "../../Media/Icon/006.svg";
//Install
import { ReactComponent as H5007L } from "../../Media/Icon/007.svg";
//Install
import { ReactComponent as B38ZM5 } from "../../Media/Icon/008.svg";
//Install
import propTypes from "prop-types";

//Element
class Brand extends Component {
    //Element
    L3DK04 = () => {
        //Element
        const { Target } = this.props
        //Element
        window.location.href = Target
    }
    //Element
    render() {
        //Element
        const { ID, Title, Facebook, X, Instagram, Youtube, Linkedin } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 q1587e" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 fgb299" },
                //Element
                React.createElement( "button", { id: "Web_G2E572", name: "Web_G2E572", className: "float-start w-100 p-0 m-0 bg-transparent border-0 text-start q0r658", title: Title, type: "button", onClick: this.L3DK04 },
                    //Element
                    React.createElement( S0L8N7, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d5h7i9" },
                //Element
                React.createElement( "menu", { className: "float-start w-100 p-0 m-0 z801en" },
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0", href: Facebook },
                            //Element
                            React.createElement( I2B824, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", href: X },
                            //Element
                            React.createElement( Y27871, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", href: Instagram },
                            //Element
                            React.createElement( RG2902, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", href: Youtube },
                            //Element
                            React.createElement( H5007L, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", href: Linkedin },
                            //Element
                            React.createElement( B38ZM5, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Brand.propTypes = {
    ID        : propTypes.string.isRequired,
    Title     : propTypes.string.isRequired,
    Facebook  : propTypes.string.isRequired,
    X         : propTypes.string.isRequired,
    Instagram : propTypes.string.isRequired,
    Youtube   : propTypes.string.isRequired,
    Linkedin  : propTypes.string.isRequired,
    Target    : propTypes.string.isRequired
}
//Element
export default Brand;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as LM8X95 } from "../../../Media/Icon/026.svg";
//Install
import { ReactComponent as XF8C15 } from "../../../Media/Icon/027.svg";

//Element
class Hamburger extends Component {
    //Element
    K9020Y = () => {
        //Element
        let UO067K = document.getElementsByTagName( "body" )[0]
        //Element
        let I470T2 = document.getElementsByClassName( "s2w751" )[0]
        //Element
        let S25N16 = document.getElementsByClassName( "b65ms1" )[0]
        //Element
        let SZ287B = document.getElementsByClassName( "k79693" )[0]
        //Element
        I470T2.classList.toggle( "d-none" )
        //Element
        S25N16.classList.toggle( "d-none" )
        //Element
        UO067K.classList.toggle( "overflow-hidden" )
        //Element
        SZ287B.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center w-auto p-0 m-0 tg05l6" },
            //Element
            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 bg-transparent border-0 s2w751", type: "button", onClick: this.K9020Y },
                //Element
                React.createElement( LM8X95, {} )
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 bg-transparent border-0 d-none b65ms1", type: "button", onClick: this.K9020Y },
                //Element
                React.createElement( XF8C15, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Hamburger;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as C22L6F } from "../../../Media/Icon/002.svg";
//Install
import { ReactComponent as G29042 } from "../../../Media/Icon/003.svg";

//Element
class SET extends Component {
    //Element
    F7674Y = () => {
        //Element
        let V3F2U4 = document.getElementsByClassName( "p88d5z" )[0]
        //Element
        V3F2U4.classList.toggle( "d-none" )
    }
    //Element
    Z1D90X = () => {
        //Element
        let Y1G460 = document.getElementsByTagName( "body" )[0]
        //Element
        let X7HQ94 = document.getElementsByClassName( "v95986" )[0]
        //Element
        X7HQ94.classList.toggle( "d-none" )
        //Element
        Y1G460.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center w-auto p-0 m-0 n1l26e" },
            //Element
            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 me-2 bg-transparent border-0 z0y4q7", type: "button", onClick: this.F7674Y },
                //Element
                React.createElement( G29042, {} )
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 bg-transparent border-0 pu7644", type: "button", onClick: this.Z1D90X },
                //Element
                React.createElement( C22L6F, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;
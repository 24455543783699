//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "swiper/css/navigation";
//Install
import "swiper/css/pagination";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Corporation from "./Component/Tab/Corporation/Default";
//Install
import Information from "./Component/Tab/Information/Default";
//Install
import Mission from "./Component/Tab/Mission/Default";
//Install
import Timeline from "./Component/Tab/Timeline/Default";
//Install
import Media from "./Component/Tab/Media/Default";
//Install
import Prize from "./Component/Tab/Prize/Default";
//Install
import Tritan from "./Component/Tab/Tritan/Default";
//Install
import Document from "./Component/Tab/Document/Default";
//Install
import Sustainability from "./Component/Tab/Sustainability/Default";
//Install
import Fair from "./Component/Tab/Fair/Default";
//Install
import Faq from "./Component/Tab/Faq/Default";
//Install
import Reference from "./Component/Tab/Reference/Default";
//Install
import Career from "./Component/Tab/Career/Default";
//Install
import Application from "./Component/Tab/Application/Default";
//Install
import Blog from "./Component/Tab/Blog/Default";
//Install
import Article from "./Component/Tab/Blog/Article";
//Install
import List from "./Component/Tab/Product/List";
//Install
import Product from "./Component/Tab/Product/Default";
//Install
import Contact from "./Component/Tab/Contact/Default";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element Prize
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/kurumsal" element={ <Corporation /> }></Route>
          <Route path="/misyon-ve-vizyon" element={ <Mission /> }></Route>
          <Route path="/bilgi-toplum-hizmetleri" element={ <Information /> }></Route>
          <Route path="/tarihce" element={ <Timeline /> }></Route>
          <Route path="/medya" element={ <Media /> }></Route>
          <Route path="/belgeler" element={ <Document /> }></Route>
          <Route path="/surdurulebilirlik" element={ <Sustainability /> }></Route>
          <Route path="/fuar" element={ <Fair /> }></Route>
          <Route path="/sik-sorulan-sorular" element={ <Faq /> }></Route>
          <Route path="/referans" element={ <Reference /> }></Route>
          <Route path="/kariyer" element={ <Career /> }></Route>
          <Route path="/hemen-basvur" element={ <Application /> }></Route>
          <Route path="/tritan" element={ <Tritan /> }></Route>
          <Route path="/odul/:Title/:ID" element={ <Prize /> }></Route>
          <Route path="/blog" element={ <Blog /> }></Route>
          <Route path="/blog-detay/:Title/:ID" element={ <Article /> }></Route>
          <Route path="/urunler" element={ <List /> }></Route>
          <Route path="/urun/:Title/:ID" element={ <Product /> }></Route>
          <Route path="/iletisim-bilgileri" element={ <Contact /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;

//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Q45902 } from "../../../Media/Icon/015.svg";
//Install
import { ReactComponent as WJ606G } from "../../../Media/Icon/016.svg";
//Install
import SEO from "../../Function/SEO";

//Element
class Default extends Component {
    //Element
    E30D9U = () => {
        //Element
        let C03Q33 = document.getElementsByClassName( "p88d5z" )[0]
        //Element
        C03Q33.classList.toggle( "d-none" )
    }
    //Element
    Z9876H = (e) => {
        //Element
        e.preventDefault()
        //Element
        let J1XH53 = "/urunler?q="
        //Element
        let J980Z3 = document.getElementById( "Header_Search_Box" ).value
        //Element
        if( J980Z3 != null && J980Z3 != "" ){
            //Element
            window.location.href = J1XH53 + J980Z3
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 u624o9" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 position-relative ct4473", onSubmit: this.Z9876H },
                //Element
                React.createElement( "input", { id: "Header_Search_Box", name: "Header_Search_Box", className: "float-start w-100 p-0 ps-5 m-0 w9y28r", placeholder: "Ara", type: "text", required: "required" } ),
                //Element
                React.createElement( "button", { id: "Header_Search_Find", name: "Header_Search_Find", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 bg-transparent border-0 xi072j", type: "submit", onClick: this.Z9876H },
                    //Element
                    React.createElement( WJ606G, {} )
                ),
                //Element
                React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute end-0 top-0 bg-transparent border-0 h8o8y9", type: "button", onClick: this.E30D9U },
                    //Element
                    React.createElement( Q45902, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import Social from "../../Shortcode/Contact/Social";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            TW15P0 : []
        }
    }
    //Element
    VJ29I8 = () => {
        //Element
        const W9L61P = async() => {
            //Element
            const NY924B = await Axios.get( "https://api.renga.com.tr/settings", {} )
            //Element
            .then( A79439 => {
                //Element
                this.setState({
                    //Element
                    TW15P0 : A79439.data
                })
            })
        }
        //Element
        W9L61P()
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "İletişim Bilgileri | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/iletisim-bilgileri"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "İletişim Bilgileri | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/iletisim-bilgileri"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "İletişim Bilgileri | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    Q98S53 = () => {
        //Element
        let Header = document.getElementsByClassName( "g283uf" )[0]
        //Element
        Header.classList.add( "q31v3i" )
    }
    //Element
    MC73O8  = () => {
        //Element
        let Footer = document.getElementsByClassName( "t52n6q" )[0]
        //Element
        Footer.classList.remove( "mt-5" )
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "İletişim Bilgileri | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/iletisim-bilgileri" )
        //Element
        this.VJ29I8()
        //Element
        this.Q98S53()
        //Element
        this.MC73O8()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hiu984" },
                //Element
                React.createElement( "div", { className: "float-start w-50 p-0 m-0 m79508" },
                    //Element
                    React.createElement( "iframe", { src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.058059802605!2d28.628383999999993!3d41.045859400000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f4e55ab929d%3A0xb7b57929ff7a0e76!2sRenga-Mercanlar%20Mutfak%20E%C5%9Fyalar%C4%B1%20-%20Merkez%20Ofis!5e0!3m2!1str!2str!4v1715768588998!5m2!1str!2str" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-50 p-0 m-0 z63h38" },
                    //Element
                    this.state.TW15P0.map( (HY29C1) => {
                        //Element
                        return React.createElement( "div", { key: HY29C1.ID, className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 ps-5 m-0 aq93o2" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 lh-1 at81s3" },
                                //Element
                                "Adres :"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 u0k73r" },
                                //Element
                                HY29C1.Address
                            ),
                            //Element
                            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 at81s3" },
                                //Element
                                "Telefon :"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 u0k73r" },
                                //Element
                                HY29C1.Telephone
                            ),
                            //Element
                            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 at81s3" },
                                //Element
                                "Fax :"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 u0k73r" },
                                //Element
                                HY29C1.Fax
                            ),
                            //Element
                            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 at81s3" },
                                //Element
                                "E-Posta :"
                            ),
                            //Element
                            React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-2 u0k73r", href: "mailto:info@renga.com.tr" },
                                //Element
                                HY29C1.Email
                            ),
                            //Element
                            React.createElement( Social, {} )
                        )
                    })
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
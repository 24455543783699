//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
class Default extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Bilgi Toplumu Hizmetleri | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/bilgi-toplum-hizmetleri"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Bilgi Toplumu Hizmetleri | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/bilgi-toplum-hizmetleri"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Bilgi Toplumu Hizmetleri | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    Q98S53 = () => {
        //Element
        let Header = document.getElementsByClassName( "g283uf" )[0]
        //Element
        Header.classList.add( "q31v3i" )
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Bilgi Toplumu Hizmetleri | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/bilgi-toplum-hizmetleri" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.Q98S53()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hiu984" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 z63h38" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Ticaret Ünvanı :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Mercanlar Mutfak Eşyaları Sanayi ve Ticaret Anonim Şirketi"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Merkez Adres :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Osmangazi Mah. Hadımköy Yolu Cad. No: 38 Doğa Plaza A Blok, 2. Kat Ofis No: 9 34538 Esenyurt / İstanbul"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Tescil Tarihi :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "06.06.2006"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Ticaret Sicil Müdürlüğü :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "İstanbul"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Sicil Numarası :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "591194-0"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Şirket Süresi :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Süresiz"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Vergi Dairesi :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Marmara Kurumlar"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Vergi Kimlik No :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "616 041 0479"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "İnternet Adresi :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "https://www.renga.com.tr/"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Mersis No :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "0616041047900001"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Kep E-mail :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "mercanlarmutfak@hs01.kep.tr"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Ödenmiş Sermaye"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "8.000.000,00 TL"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Taahüd Edilen Sermaye"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "8.000.000,00 TL"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Temsil ve İlzam Süresi"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "3 Yıl (13.06.2026)"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Faaliyet Konusu :"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Her türlü cam ve camdan mamul mutfak eşyaları porselen plastik mutfak eşyaları her türlü hediyelik eşya imalatı alımı satımı ithalatı ve ihracatı, ve anonim şirket esas mukavelesinde yazılı olan diğer işler"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Şirket Temsil ve İlzama Yetkili  "
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Metin ALBAYRAK 13.06.2023 Tarihli Genel Kurul Kararı ile Yönetim Kurulu Başkanı"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Bağımsız Denetçi"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Sky Bagımsız Denetim A.Ş."
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Bağımsız Denetim Dönemi"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "01.01.2023 - 31.12.2023"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Bağımsız Denetim Resmi Sicil"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "İstanbul-451442-5"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Bağımsz Denetim Adres"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Esentepe Mahallesi Büyükdere Caddesi No:126/C Blok Kat:5 Özsezen İş Merkezi Şişli / İstanbul"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 aq93o2" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 lh-1 at81s3" },
                                                //Element
                                                React.createElement( "strong", {},
                                                    //Element
                                                    "Bağmsız Denetim  İletişim"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 u0k73r" },
                                                //Element
                                                "Telefon: 0 (212) 272 71 71 – Email: info@skydenetim.com.tr"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
class Information extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            R1283H : []
        }
    }
    //Element
    BI10J4 = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/settings", {} )
        //Element
        .then( F1J198 => {
            //Element
            this.setState({
                //Element
                R1283H : F1J198.data
            })
        })
    }
    //Element
    componentDidMount(){
        //Element
        this.BI10J4()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 gf522g" },
            //Element
            this.state.R1283H.map( (Data) => {
                //Element
                return React.createElement( Row, { key: Data.ID },
                    //Element
                    React.createElement( Col, { key: Data.ID, md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 u6v625" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 y84r0n" },
                                //Element
                                "Merkez Ofis Adres :"
                            ),
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 u745i2" },
                                //Element
                                Data.Address
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 u6v625" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 y84r0n" },
                                //Element
                                "Telefon"
                            ),
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 u745i2" },
                                //Element
                                Data.Telephone
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 u6v625" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 y84r0n" },
                                //Element
                                "E-mail"
                            ),
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-2 u745i2", href: "mailto:info@renga.com.tr" },
                                //Element
                                Data.Email
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 u6v625" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 y84r0n" },
                                //Element
                                "İstoç Renga Showroom Adres :"
                            ),
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 u745i2" },
                                //Element
                                Data.Showroom
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 u6v625" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 y84r0n" },
                                //Element
                                "Telefon"
                            ),
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 u745i2" },
                                //Element
                                Data.Showroom_Telephone
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 u6v625" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 y84r0n" },
                                //Element
                                "E-mail"
                            ),
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-2 u745i2", href: "mailto:info@renga.com.tr" },
                                //Element
                                Data.Showroom_Email
                            )
                        )
                    )
                )
            })
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Information;
//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            D27D99 : []
        }
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Belgelerimiz | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/belgeler"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Belgelerimiz | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/belgeler"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Belgelerimiz | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    ZWJ545 = () => {
        //Element
        const K8182H = async() => {
            //Element
            const R5L5M9 = Axios.get( "https://api.renga.com.tr/document", {} )
            //Element
            .then( T9F36I => {
                //Element
                this.setState({
                    //Element
                    D27D99 : T9F36I.data
                })
            })
        }
        //Element
        K8182H()
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Belgelerimiz | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/belgeler" )
        //Element
        this.ZWJ545()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        let SM256B = "https://api.renga.com.tr/images/"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Breadcrumb, { ID: "0", Title: "Belgelerimiz", Target: "/belgeler" } )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 tyr830" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "Belgelerimiz"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 g1975z" },
                //Element
                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-uppercase stc852" },
                    //Element
                    "Belgelerimiz"
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 m3f94c" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 m139we" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            this.state.D27D99.map( (G17RL5) => {
                                //Element
                                return React.createElement( Col, { key: G17RL5.ID, md:3 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 io1105" },
                                        //Element
                                        React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-5 m-0 i471wq" },
                                            //Element
                                            React.createElement( "img", { alt: G17RL5.Title, title: G17RL5.Title, src: SM256B + G17RL5.Image, width: "208", height: "auto" } )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-5 ps-4 pe-4 m-0 yh87p3" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 text-center ch6520" },
                                                //Element
                                                G17RL5.Title
                                            )
                                        )
                                    )
                                )
                            })
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as L93YE9 } from "../../../Media/Icon/017.svg";
//Install
import { ReactComponent as TR941A } from "../../../Media/Icon/018.svg";

//Element
class Navigation extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 co074r" },
            //Element
            React.createElement( "button", { id: "Web_W474VO", name: "Web_W474VO", className: "float-start w-100 p-0 m-0 bg-transparent border-0 position-absolute m5v463", type: "button" },
                //Element
                React.createElement( L93YE9, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_E4090L", name: "Web_E4090L", className: "float-start w-100 p-0 m-0 bg-transparent border-0 position-absolute q3836l", type: "button" },
                //Element
                React.createElement( TR941A, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Navigation;
//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import SEO from "../../Function/SEO";
//Install
import Filter from "../../Shortcode/Product/Filter";
//Install
import Card from "../../Shortcode/Product/Card";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class List extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            F6670V : [],
            //Element
            X8X956 : [],
            //Element
            D5316Y : [],
            //Element
            U0T026 : [],
            //Element
            ED57I1 : [],
            //Element
            C9141C : "",
            //Element
            Y721UF : "",
            //Element
            KC072Y : "",
            //Element
            LM310M : "",
            //Element
            R2W3Q6 : ""
        }
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Ürünler | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/urunler"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Ürünler | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/urunler"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Ürünler | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    X0H70P = () => {
        //Element
        let P36R26 = window.location.search
        //Element
        let W4Z7O7 = new URLSearchParams( P36R26 )
        //Element
        let X125QB = W4Z7O7.get( "variety" )
        //Element
        let H829EV = W4Z7O7.get( "bottle" )
        //Element
        let ZP9160 = W4Z7O7.get( "volume" )
        //Element
        let BO19Q6 = W4Z7O7.get( "color" )
        //Element
        let Y8ZQ30 = W4Z7O7.get( "filter" )
        //Element
        let Y320XZ = W4Z7O7.get( "q" )
        //Element
        this.setState({
            //Element
            T2W83R : X125QB,
            //Element
            N308H4 : H829EV,
            //Element
            G7W0S5 : ZP9160,
            //Element
            Z627ZT : BO19Q6,
        })
        //Element
        if( Y8ZQ30 != null ){
            //Element
            if( ZP9160 == null ){
                //Element
                ZP9160 = "0"
            }
            //Element
            const QI633Y = async() => {
                //Element
                const J9LF15 = await Axios.get( "https://api.renga.com.tr/product/filter", {
                    //Element
                    params : {
                        //Element
                        Variety : X125QB,
                        //Element
                        Bottle  : H829EV,
                        //Element
                        Volume  : ZP9160
                    }
                })
                //Element
                .then( D445FN => {
                    //Element
                    if( D445FN.data.length != 0 ){
                        //Element
                        this.setState({
                            //Element
                            ED57I1 : D445FN.data
                        })
                    //Element
                    } else {
                        //Element
                        document.getElementsByClassName( "x26a7o" )[0].classList.remove( "opacity-0" )
                    }
                })
            }
            //Element
            QI633Y()
        //Element
        } else if( X125QB != null ){
            //Element
            const QI633Y = async() => {
                //Element
                const J9LF15 = await Axios.get( "https://api.renga.com.tr/product/variety", {
                    //Element
                    params : {
                        //Element
                        ID : X125QB
                    }
                })
                //Element
                .then( D445FN => {
                    //Element
                    if( D445FN.data.length != 0 ){
                        //Element
                        this.setState({
                            //Element
                            ED57I1 : D445FN.data
                        })
                    //Element
                    } else {
                        //Element
                        document.getElementsByClassName( "x26a7o" )[0].classList.remove( "opacity-0" )
                    }
                })
            }
            //Element
            QI633Y()
        //Element
        } else if( H829EV != null ){
            //Element
            const QI633Y = async() => {
                //Element
                const J9LF15 = await Axios.get( "https://api.renga.com.tr/product/bottle", {
                    //Element
                    params : {
                        //Element
                        ID : H829EV
                    }
                })
                //Element
                .then( D445FN => {
                    //Element
                    if( D445FN.data.length != 0 ){
                        //Element
                        this.setState({
                            //Element
                            ED57I1 : D445FN.data
                        })
                    //Element
                    } else {
                        //Element
                        document.getElementsByClassName( "x26a7o" )[0].classList.remove( "opacity-0" )
                    }
                })
            }
            //Element
            QI633Y()
        //Element
        } else if( ZP9160 != null ){
            //Element
            const QI633Y = async() => {
                //Element
                const J9LF15 = await Axios.get( "https://api.renga.com.tr/product/volume", {
                    //Element
                    params : {
                        //Element
                        ID : ZP9160
                    }
                })
                //Element
                .then( D445FN => {
                    //Element
                    if( D445FN.data.length != 0 ){
                        //Element
                        this.setState({
                            //Element
                            ED57I1 : D445FN.data
                        })
                    //Element
                    } else {
                        //Element
                        document.getElementsByClassName( "x26a7o" )[0].classList.remove( "opacity-0" )
                    }
                })
            }
            //Element
            QI633Y()
        //Element
        } else if( BO19Q6 != null ){
            //Element
            const QI633Y = async() => {
                //Element
                const J9LF15 = await Axios.get( "https://api.renga.com.tr/product/color/order", {
                    //Element
                    params : {
                        //Element
                        ID : BO19Q6
                    }
                })
                //Element
                .then( D445FN => {
                    //Element
                    if( D445FN.data.length != 0 ){
                        //Element
                        this.setState({
                            //Element
                            R71I55 : D445FN.data
                        })
                        //Element
                        this.state.R71I55.map( (N14K12) => {
                            //Element
                            const J9LF15 = Axios.get( "https://api.renga.com.tr/product/select", {
                                //Element
                                params : {
                                    //Element
                                    ID : N14K12.Product
                                }
                            })
                            //Element
                            .then( FR14Y9 => {
                                //Element
                                FR14Y9.data.map( (VD0T26) => {
                                    //Element
                                    this.state.ED57I1.push(VD0T26)
                                })
                            })
                        })
                    //Element
                    } else {
                        //Element
                        document.getElementsByClassName( "x26a7o" )[0].classList.remove( "opacity-0" )
                    }
                })
            }
            //Element
            QI633Y()
        //Element
        } else if( Y320XZ != null ){
            //Element
            const QI633Y = async() => {
                //Element
                const J9LF15 = await Axios.get( "https://api.renga.com.tr//product/search", {
                    //Element
                    params : {
                        //Element
                        Like : Y320XZ
                    }
                })
                //Element
                .then( D445FN => {
                    //Element
                    if( D445FN.data.length != 0 ){
                        //Element
                        this.setState({
                            //Element
                            ED57I1 : D445FN.data
                        })
                    //Element
                    } else {
                        //Element
                        document.getElementsByClassName( "x26a7o" )[0].classList.remove( "opacity-0" )
                    }
                })
            }
            //Element
            QI633Y()
        //Element
        } else {
            //Element
            const QI633Y = async() => {
                //Element
                const J9LF15 = await Axios.get( "https://api.renga.com.tr/product", {} )
                //Element
                .then( D445FN => {
                    //Element
                    if( D445FN.data.length != 0 ){
                        //Element
                        this.setState({
                            //Element
                            ED57I1 : D445FN.data
                        })
                    //Element
                    } else {
                        //Element
                        document.getElementsByClassName( "x26a7o" )[0].classList.remove( "opacity-0" )
                    }
                })
            }
            //Element
            QI633Y()
        }
    }
    //Element
    S15R1J = () => {
        //Element
        const P36R26 = window.location.search
        //Element
        const W4Z7O7 = new URLSearchParams( P36R26 )
        //Element
        const X125QB = W4Z7O7.get( "variety" )
        //Element
        let U6271P = "Category_" + X125QB
        //Element
        const TOP712 = async() => {
            //Element
            const R75663 = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            .then( J743PD => {
                //Element
                this.setState({
                    //Element
                    F6670V : J743PD.data
                })
                //Element
                if( document.getElementById( U6271P ) != null ){
                    //Element
                    document.getElementById( U6271P ).checked = true
                }
            })
            //Element
            const E3F5B9 = await Axios.get( "https://api.renga.com.tr/color", {} )
            //Element
            this.setState({
                //Element
                U0T026 : E3F5B9.data
            })
        }
        //Element
        TOP712()
    }
    //Element
    P1I80H = (e) => {
        //Element 
        let Z93919 = e.nativeEvent.srcElement.childNodes[1].value
        //Element
        let X9LW10 = document.querySelectorAll( "#Web_X9LW10 input" )
        //Element
        let ES1T50 = document.getElementById( "Web_PG76D1" )
        //Element
        for (let Index = 0; Index < X9LW10.length; Index++) {
            //Element
            X9LW10[Index].checked = false
        }
        //Element
        e.nativeEvent.srcElement.childNodes[1].checked = true
        //Element
        ES1T50.classList.remove( "d-none" )
        //Element
        const C8M21Y = async() => {
            //Element
            const NPV972 = await Axios.get( "https://api.renga.com.tr/bottle/category", {
                //Element
                params : {
                    //Element
                    ID : Z93919
                }
            })
            //Element
            this.setState({
                //Element
                X8X956 : NPV972.data
            })
            //Element
            const M146F6 = await Axios.get( "https://api.renga.com.tr/volume/category", {
                //Element
                params : {
                    //Element
                    ID : Z93919
                }
            })
            //Element
            .then( K82H2R => {
                //Element
                this.setState({
                    //Element
                    D5316Y : K82H2R.data
                })
                //Element
                if( K82H2R.data.length > 0 ){
                    //Element
                    document.getElementById( "Web_PG76D1" ).classList.remove( "d-none" )
                    //Element
                    document.getElementById( "Web_I071KA" ).classList.remove( "d-none" )
                    //Element
                    document.getElementById( "Web_C94I2L" ).classList.remove( "d-none" )
                    //Element
                    document.getElementById( "Web_G52X13" ).classList.remove( "d-none" )
                    //Element
                    document.getElementById( "Web_K4318E" ).classList.remove( "d-none" )
                    //Element
                    document.getElementById( "Web_W3849Y" ).classList.remove( "d-none" )
                    //Element
                    document.getElementsByClassName( "v9i57a" )[0].classList.remove( "d-none" )
                } else {
                    //Element
                    document.getElementById( "Web_C94I2L" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_G52X13" ).classList.add( "d-none" )
                    //Element
                    document.getElementsByClassName( "v9i57a" )[0].classList.add( "d-none" )
                }
            })
        }
        //Element
        C8M21Y()
        //Element
        this.setState({
            //Element
            C9141C : Z93919
        })
    }
    //Element
    Q7Y53H = (e) => {
        //Element 
        let Z93919 = e.nativeEvent.srcElement.childNodes[1].value
        //Element
        let X9LW10 = document.querySelectorAll( "#Web_I071KA input" )
        //Element
        for (let Index = 0; Index < X9LW10.length; Index++) {
            //Element
            X9LW10[Index].checked = false
        }
        //Element
        e.nativeEvent.srcElement.childNodes[1].checked = true
        //Element
        this.setState({
            //Element
            Y721UF : Z93919
        })
    }
    //Element
    JG504Q = (e) => {
        //Element 
        let Z93919 = e.nativeEvent.srcElement.childNodes[1].value
        //Element
        let X9LW10 = document.querySelectorAll( "#Web_G52X13 input" )
        //Element
        for (let Index = 0; Index < X9LW10.length; Index++) {
            //Element
            X9LW10[Index].checked = false
        }
        //Element
        e.nativeEvent.srcElement.childNodes[1].checked = true
        //Element
        this.setState({
            //Element
            KC072Y : Z93919
        })
    }
    //Element
    XC5S09 = (e) => {
        //Element 
        let Z93919 = e.nativeEvent.srcElement.childNodes[1].value
        //Element
        let X9LW10 = document.querySelectorAll( "#Web_W3849Y input" )
        //Element
        for (let Index = 0; Index < X9LW10.length; Index++) {
            //Element
            X9LW10[Index].checked = false
        }
        //Element
        e.nativeEvent.srcElement.childNodes[1].checked = true
        //Element
        this.setState({
            //Element
            LM310M : Z93919
        })
    }
    //Element
    JT93I4 = (e) => {
        //Element  
        e.preventDefault()
        //Element 
        let T58QX9 = "/urunler/"
        //Element
        let A72D39 = ""
        //Element
        let M12H8V = "?filter=1"
        //Element
        let A2602J = this.state.C9141C
        //Element
        let BI864L = this.state.Y721UF
        //Element
        let Z2P4P5 = this.state.KC072Y
        //Element
        let I966GW = this.state.LM310M
        //Element
        if( A2602J != null && A2602J != "" ){
            //Element
            A72D39 = A72D39 + M12H8V + "&variety=" + A2602J
        }
        //Element
        if( BI864L != null && BI864L != "" ){
            //Element
            A72D39 = A72D39 + "&bottle=" + BI864L
        }
        //Element
        if( Z2P4P5 != null && Z2P4P5 != "" ){
            //Element
            A72D39 = A72D39 + "&volume=" + Z2P4P5
        }
        //Element
        if( I966GW != null && I966GW != "" ){
            //Element
            A72D39 = A72D39 + "&color=" + I966GW
        }
        //Element
        window.location.href = T58QX9 + A72D39
    }
    //Element
    L0E166 = () => {
        //Element
        const P36R26 = window.location.search
        //Element
        const W4Z7O7 = new URLSearchParams( P36R26 )
        //Element
        const X125QB = W4Z7O7.get( "variety" )
        //Element
        const H829EV = W4Z7O7.get( "bottle" )
        //Element
        const ZP9160 = W4Z7O7.get( "volume" )
        //Element
        const BO19Q6 = W4Z7O7.get( "color" )
        //Element
        let XF414X   = "Group_" + H829EV
        //Element
        let K052B3   = "Volume_" + ZP9160
        //Element
        let W94EH8   = "Color_" + BO19Q6
        //Element
        if( X125QB == "44" ){
            //Element
            document.getElementById( "Web_T34L36" ).style.backgroundColor = "#FF6600"
            //Element
            document.querySelector( "#Web_T34L36 span" ).style.color = "#FFFFFF"
        //Element
        } else if( X125QB == "50" ){
            //Element
            document.getElementById( "Web_W3R3Z7" ).style.backgroundColor = "#FF6600"
            //Element
            document.querySelector( "#Web_W3R3Z7 span" ).style.color = "#FFFFFF"
        //Element
        } else if( X125QB == "48" ){
            //Element
            document.getElementById( "Web_YF568B" ).style.backgroundColor = "#FF6600"
            //Element
            document.querySelector( "#Web_YF568B span" ).style.color = "#FFFFFF"
        //Element
        } else if( X125QB == "47" ){
            //Element
            document.getElementById( "Web_G37179" ).style.backgroundColor = "#FF6600"
            //Element
            document.querySelector( "#Web_G37179 span" ).style.color = "#FFFFFF"
        }
        //Element
        if( H829EV != null && X125QB != null ){
            //Element
            const C8M21Y = async() => {
                //Element
                const NPV972 = await Axios.get( "https://api.renga.com.tr/bottle/category", {
                    //Element
                    params : {
                        //Element
                        ID : X125QB
                    }
                })
                //Element
                .then( GJF003 => {
                    //Element
                    this.setState({
                        //Element
                        X8X956 : GJF003.data
                    })
                    //Element
                    if( GJF003.statusText == "OK" ){
                        //Element
                        if( document.getElementById( XF414X ) != null ){
                            //Element
                            document.getElementById( XF414X ).checked = true
                        }
                    }
                })
                
            }
            //Element
            C8M21Y()
            //Element
            document.getElementById( "Web_PG76D1" ).classList.remove( "d-none" )
            //Element
            document.getElementById( "Web_I071KA" ).classList.remove( "d-none" )
            //Element
            document.getElementsByClassName( "v9i57a" )[0].classList.remove( "d-none" )
        }
        //Element
        if( ZP9160 != null && X125QB != null ){
            //Element
            const C8M21Y = async() => {
                //Element
                const M146F6 = await Axios.get( "https://api.renga.com.tr/volume/category", {
                    //Element
                    params : {
                        //Element
                        ID : X125QB
                    }
                })
                //Element
                .then( K82H2R => {
                    //Element
                    this.setState({
                        //Element
                        D5316Y : K82H2R.data
                    })
                    //Element
                    if( K82H2R.statusText == "OK" ){
                        //Element
                        if( document.getElementById( K052B3 ) != null ){
                            //Element
                            document.getElementById( K052B3 ).checked = true
                        }
                    }
                })
            }
            //Element
            C8M21Y()
            //Element
            document.getElementById( "Web_C94I2L" ).classList.remove( "d-none" )
            //Element
            document.getElementById( "Web_G52X13" ).classList.remove( "d-none" )
        }
        //Element
        if( BO19Q6 != null ){
            //Element
            const C8M21Y = async() => {
                //Element
                const E3F5B9 = await Axios.get( "https://api.renga.com.tr/color", {} )
                //Element
                .then( M836CY => {
                    //Element
                    this.setState({
                        //Element
                        U0T026 : M836CY.data
                    })
                    //Element
                    if( M836CY.statusText == "OK" ){
                        //Element
                        if( document.getElementById( W94EH8 ) != null ){
                            //Element
                            document.getElementById( W94EH8 ).checked = true
                        }
                    }
                })
            }
            //Element
            C8M21Y()
            //Element
            document.getElementById( "Web_K4318E" ).classList.remove( "d-none" )
            //Element
            document.getElementById( "Web_W3849Y" ).classList.remove( "d-none" )
        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Ürünler | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/urunler" )
        //Element
        this.S15R1J()
        //Element
        this.X0H70P()
        //Element
        this.L0E166()
        //Element
        this.N2782S()
        //Element
        this.Q8283L()
        //Element
        this.EI13R9()
    }
    //Element
    render() {
        //Element
        let L6866G, H2K0D1, N199QN, P80Q7E, O897I3, K8364K, KT889Q
        //Element
        L6866G = "/urun/"
        //Element
        H2K0D1 = "/"
        //Element
        N199QN = "-"
        //Element
        P80Q7E = "variety"
        //Element
        O897I3 = "bottle"
        //Element
        K8364K = "volume"
        //Element
        KT889Q = "color"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ib891j" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Ürünler", Target: "/urunler" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 h11360" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "Ürünler"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 j563d9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative j1266m" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 n49nl9" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n14h18" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 k6633e" },
                                                    //Element
                                                    "Arama"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 pt-4 pb-4 m-0 uzs554" },
                                                //Element
                                                React.createElement( "span", { id: "Web_C72O87", className: "float-start w-100 p-0 pb-2 m-0 iw218u" },
                                                    //Element
                                                    "Ürünler"
                                                ),
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 m49g25" },
                                                    //Element
                                                    React.createElement( "fieldset", { id: "Web_X9LW10", className: "float-start w-100 p-0 m-0 mt-3 x2qb85" },
                                                        //Element
                                                        this.state.F6670V.map( (S7B4P6) => {
                                                            //Element
                                                            return React.createElement( "div", { key: S7B4P6.ID, className: "float-start w-100 position-relative j2jf75", onClick: this.P1I80H },
                                                                //Element
                                                                S7B4P6.Title,
                                                                //Element
                                                                React.createElement( "input", { id: "Category_" + S7B4P6.ID, className: "float-start w-auto p-0 m-0 r6845x", value: S7B4P6.ID, type: "checkbox" } ),
                                                                //Element
                                                                React.createElement( "label", { className: "float-start p-0 m-0 fd9k67" } )
                                                            )
                                                        })
                                                    )
                                                ),
                                                //Element
                                                React.createElement( "span", { id: "Web_PG76D1", className: "float-start w-100 p-0 pb-2 m-0 mt-3 d-none iw218u" },
                                                    //Element
                                                    "Ürün Grubu"
                                                ),
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 m49g25" },
                                                    //Element
                                                    React.createElement( "fieldset", { id: "Web_I071KA", className: "float-start w-100 p-0 m-0 mt-3 d-none x2qb85" },
                                                        //Element
                                                        this.state.X8X956.map( (S7B4P6) => {
                                                            //Element
                                                            return React.createElement( "div", { key: S7B4P6.ID, className: "float-start w-100 position-relative j2jf75", onClick: this.Q7Y53H },
                                                                //Element
                                                                S7B4P6.Title,
                                                                //Element
                                                                React.createElement( "input", { id: "Group_" + S7B4P6.ID, className: "float-start w-auto p-0 m-0 r6845x", value: S7B4P6.ID, type: "checkbox" } ),
                                                                //Element
                                                                React.createElement( "label", { className: "float-start p-0 m-0 fd9k67" } )
                                                            )
                                                        })
                                                    )
                                                ),
                                                //Element
                                                React.createElement( "span", { id: "Web_C94I2L", className: "float-start w-100 p-0 pb-2 m-0 mt-3 d-none iw218u" },
                                                    //Element
                                                    "Hacim"
                                                ),
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 m49g25" },
                                                    //Element
                                                    React.createElement( "fieldset", { id: "Web_G52X13", className: "float-start w-100 p-0 m-0 mt-3 d-none x2qb85" },
                                                        //Element
                                                        this.state.D5316Y.map( (S7B4P6) => {
                                                            //Element
                                                            return React.createElement( "div", { key: S7B4P6.ID, className: "float-start w-100 position-relative j2jf75", onClick: this.JG504Q },
                                                                //Element
                                                                S7B4P6.Title,
                                                                //Element
                                                                React.createElement( "input", { id: "Volume_" + S7B4P6.ID, className: "float-start w-auto p-0 m-0 r6845x", value: S7B4P6.ID, type: "checkbox" } ),
                                                                //Element
                                                                React.createElement( "label", { className: "float-start p-0 m-0 fd9k67" } )
                                                            )
                                                        })
                                                    )
                                                ),
                                                //Element
                                                React.createElement( "span", { id: "Web_K4318E", className: "float-start w-100 p-0 pb-2 m-0 mt-3 d-none iw218u" },
                                                    //Element
                                                    "Renk"
                                                ),
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 m49g25" },
                                                    //Element
                                                    React.createElement( "fieldset", { id: "Web_W3849Y", className: "float-start w-100 p-0 m-0 mt-3 d-none x2qb85" },
                                                        //Element
                                                        this.state.U0T026.map( (S7B4P6) => {
                                                            //Element
                                                            return React.createElement( "div", { key: S7B4P6.ID, className: "float-start w-100 position-relative j2jf75", onClick: this.XC5S09 },
                                                                //Element
                                                                S7B4P6.Title,
                                                                //Element
                                                                React.createElement( "input", { id: "Color_" + S7B4P6.ID, className: "float-start w-auto p-0 m-0 r6845x", value: S7B4P6.ID, type: "checkbox" } ),
                                                                //Element
                                                                React.createElement( "label", { className: "float-start p-0 m-0 fd9k67" } )
                                                            )
                                                        })
                                                    )
                                                ),
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 d-none v9i57a" },
                                                    //Element
                                                    React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 y68e5u", type: "button", onClick: this.JT93I4 },
                                                        //Element
                                                        "Arama"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:9 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 iwb979" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:3 },
                                                    //Element
                                                    React.createElement( Filter, { ID: "Web_T34L36", Title: "Mataralar", Image: "", Target: "/urunler/?variety=44" } )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:3 },
                                                    //Element
                                                    React.createElement( Filter, { ID: "Web_W3R3Z7", Title: "Mutfak Ürünleri", Image: "", Target: "/urunler/?variety=50" } )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:3 },
                                                    //Element
                                                    React.createElement( Filter, { ID: "Web_YF568B", Title: "Bebek Ürünleri", Image: "", Target: "/urunler/?variety=48" } )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:3 },
                                                    //Element
                                                    React.createElement( Filter, { ID: "Web_G37179", Title: "Çocuk Ürünleri", Image: "", Target: "/urunler/?variety=47" } )
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 od8w32" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                this.state.ED57I1.map( (XY7Z33) => {
                                                    //Element
                                                    return React.createElement( Col, { key: XY7Z33.ID, md:3 },
                                                        //Element
                                                        React.createElement( Card, { ID: XY7Z33.ID.toString(), Title: XY7Z33.Title, Summary: XY7Z33.SubTitle, Image: XY7Z33.Image, Target: L6866G + SEO( XY7Z33.Title + N199QN + XY7Z33.SubTitle ) + H2K0D1 + XY7Z33.ID } )
                                                    )
                                                }),
                                                //Element
                                                React.createElement( Col, { md:12 },
                                                    //Element
                                                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 opacity-0 x26a7o" },
                                                        //Element
                                                        React.createElement( "label", { className: "float-start w-auto p-0 m-0 mt-5 gm405v" },
                                                            //Element
                                                            "Herhangi Bir Ürün Bulunamadı!"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default List;
//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import SEO from "../../Function/SEO";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            Y0XE89 : []
        }
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Blog | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/blog"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Blog | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/blog"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Blog | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    A82W2F = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/blog", {} )
        //Element
        .then( R7681X => {
            //Element
            this.setState({
                //Element
                Y0XE89 : R7681X.data
            })
        })
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Blog | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/blog" )
        //Element
        this.A82W2F()
    }
    //Element
    render() {
        //Element
        let YWV470, X2677F, T9191W
        //Element
        YWV470 = "https://api.renga.com.tr/images/"
        //Element
        X2677F = "/blog-detay/"
        //Element
        T9191W = "/"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Breadcrumb, { ID: "0", Title: "Blog", Target: "/blog" } )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d0124i" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "Blog"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ut5701" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        this.state.Y0XE89.map( (F047Q6) => {
                            //Element
                            return React.createElement( Col, { key: F047Q6.ID, md:6 },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-5 sp766s", href: X2677F + SEO(F047Q6.Title) + T9191W + F047Q6.ID },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 b529h1", style: { backgroundImage: "url("+ YWV470 + F047Q6.Image +")" } } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 justify-content-center p-0 m-0 xi552q" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-3 y3j898" },
                                            //Element
                                            F047Q6.Date
                                        ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-3 q17t7m" },
                                            //Element
                                            F047Q6.Category
                                        )
                                    ),
                                    //Element
                                    React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-2 text-center a8l6a8" },
                                        //Element
                                        F047Q6.Title
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center wf5430" },
                                        //Element
                                        F047Q6.Summary
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 justify-content-center p-0 m-0" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center a901p8" },
                                            //Element
                                            "Devamını Oku"
                                        )
                                    )
                                )
                            )
                        })
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import propTypes from "prop-types";

//Element
class Card extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            AQ285A : [],
            //Element
            Z67U2O : null,
            //Element
            QU71E7 : null,
            //Element
            KCW358 : null
        }
    }
    //Element
    L50T1C = () => {
        //Element
        const { ID } = this.props
        //Element
        const M0BG27 = async() => {
            //Element
            const U9Z54E = await Axios.get( "https://api.renga.com.tr/product/color/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( O66IX2 => {
                //Element
                if( O66IX2.data[0] != null ){
                    //Element
                    if( O66IX2.data[0].First != null && O66IX2.data[0].First != "0" ){
                        //Element
                        const K1A6M8 = Axios.get( "https://api.renga.com.tr/color/select", {
                            //Element
                            params : {
                                //Element
                                ID : O66IX2.data[0].First
                            }
                        })
                        //Element
                        .then( X9Y944 => {
                            //Element
                            this.setState({
                                //Element
                                Z67U2O : X9Y944.data[0].RGB
                            })
                            //Element
                            if( X9Y944.statusText == "OK" ){
                                //Element
                                if( O66IX2.data[0].Second != null && O66IX2.data[0].Second != "0" ){
                                    //Element
                                    const F7730V = Axios.get( "https://api.renga.com.tr/color/select", {
                                        //Element
                                        params : {
                                            //Element
                                            ID : O66IX2.data[0].Second
                                        }
                                    })
                                    //Element
                                    .then( UC747L => {
                                        //Element
                                        this.setState({
                                            //Element
                                            QU71E7 : UC747L.data[0].RGB
                                        })
                                        //Element
                                        if( UC747L.statusText == "OK" ){
                                            //Element
                                            if( O66IX2.data[0].Third != null && O66IX2.data[0].Third != "0" ){
                                                //Element
                                                const SQ0864 = Axios.get( "https://api.renga.com.tr/color/select", {
                                                    //Element
                                                    params : {
                                                        //Element
                                                        ID : O66IX2.data[0].Third
                                                    }
                                                })
                                                //Element
                                                .then( XLG099 => {
                                                    //Element
                                                    this.setState({
                                                        //Element
                                                        KCW358 : XLG099.data[0].RGB
                                                    })
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        }
        //Element
        M0BG27()
    }
    //Element
    componentDidMount(){
        //Element
        this.L50T1C()
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Image, Target } = this.props
        //Element
        let S571KC = "https://api.renga.com.tr/images/"
        //Element
        let G9743Z = S571KC + Image
        //Element
        let ZP234T, NJ04W5, E00FR2
        //Element
        if( this.state.Z67U2O != null ){
            //Element
            ZP234T = React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 rounded-circle", style: { backgroundColor: this.state.Z67U2O } } )
            )
        }
        //Element
        if( this.state.QU71E7 != null ){
            //Element
            NJ04W5 = React.createElement( "li", { className: "float-start w-auto" },
                //Element
                 React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 rounded-circle", style: { backgroundColor: this.state.QU71E7 } } )
            )
        }
        //Element
        if( this.state.KCW358 != null ){
            //Element
            E00FR2 = React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 rounded-circle", style: { backgroundColor: this.state.KCW358 } } )
            )
        }
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 pb-3 m-0 mt-4 plb343", href: Target },
            //Element
            React.createElement( "figure", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative v57hk2", style: { backgroundImage: "url("+ G9743Z +")" } } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-3 pt-0 m-0 d5j549" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate n808g5" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-center j63i8l" },
                    //Element
                    Summary
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 m50f7z" },
                    //Element
                    React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 hs382u" },
                        //Element
                        ZP234T,
                        //Element
                        NJ04W5,
                        //Element
                        E00FR2
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Card;
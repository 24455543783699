//Install
import React, { Component } from "react";
//Install
import { ReactComponent as D361E2 } from "../../Media/Icon/012.svg";
//Install
import { ReactComponent as QA871O } from "../../Media/Icon/013.svg";

//Element
class Navigation extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-end p-0 m-0 w569a3" },
            //Element
            React.createElement( "button", { id: "Web_LZ2Y65", name: "Web_LZ2Y65", className: "float-start w-auto p-0 m-0 me-2 bg-transparent border-0 n1w525", type: "button" },
                //Element
                React.createElement( D361E2, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_D5026K", name: "Web_D5026K", className: "float-start w-auto p-0 m-0 bg-transparent border-0 n1w525", type: "button" },
                //Element
                React.createElement( QA871O, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Navigation;
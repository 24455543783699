//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            M20M66 : []
        }
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "İnsan Kaynakları | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/insan-kaynaklari"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "İnsan Kaynakları | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/insan-kaynaklari"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "İnsan Kaynakları | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    XW552S = () => {
        //Element
        const G740RL = async() => {
            //Element
            const N650M9 = await Axios.get( "https://api.renga.com.tr/career", {} )
            //Element
            .then( U38K02 => {
                //Element
                this.setState({
                    //Element
                    M20M66 : U38K02.data
                })
            })
        }
        //Element
        G740RL()
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "İnsan Kaynakları | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/insan-kaynaklari" )
        //Element
        this.XW552S()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Breadcrumb, { ID: "0", Title: "İnsan Kaynakları", Target: "/insan-kaynaklari" } )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u09dl7" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "İnsan Kaynakları"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t46604" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pt-3 m-0 y422cf" },
                                //Element
                                this.state.M20M66.map( (QF9443) => {
                                    //Element
                                    return Parser( QF9443.Summary )
                                }),
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 text-center r230tq", href: "/hemen-basvur" },
                                    //Element
                                    "Hemen Başvur"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Award extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Image, Target } = this.props
        //Element
        const { One, Two, Three }  = this.props
        //Element
        let K571NZ = "https://api.renga.com.tr/images/"
        //Element
        let H0HT35  = K571NZ + Image
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 position-relative f8c07g", href: Target },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center rounded-circle p4794g" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: H0HT35, width: "150", height: "auto" } ),
            ),
            //Element
            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 a2949r" },
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center" },
                        //Element
                        One
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center" },
                        //Element
                        Two
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center" },
                        //Element
                        Three
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Award.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    One    : propTypes.string.isRequired,
    Two    : propTypes.string.isRequired,
    Three  : propTypes.string.isRequired,
    Image  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Award;
//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Blog extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Image, Time, Category, Target } = this.props
        //Element
        let C2643L = "https://api.renga.com.tr/images/"
        //Element
        let B0U82M = C2643L + Image
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-4 m-0 u6c403", title: Title, href: Target },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 v62759", style: { backgroundImage: "url( "+ B0U82M +" )"} },
                        //Element
                        
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 pt-3 pb-4 ps-3 pe-3 m-0 yl0k42" },
                        //Element
                        React.createElement( "h5", { className: "float-start w-auto p-0 m-0 r4t7v5" },
                            //Element
                            Title
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 o5x395" },
                            //Element
                            Summary
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center i2w894" },
                            //Element
                            "Devamını Oku"
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Blog.propTypes = {
    ID          : propTypes.string.isRequired,
    Title       : propTypes.string.isRequired,
    Summary     : propTypes.string.isRequired,
    Image       : propTypes.string.isRequired,
    Time        : propTypes.string.isRequired,
    Category    : propTypes.string.isRequired,
    Target      : propTypes.string.isRequired
}
//Element
export default Blog;